import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import styles from '../styles/Gallery.module.css';

class Gallery extends React.Component{
    render() {

        const { images, legends } = this.props;

        return (
            <div style={{borderRadius: '10px', overflow: 'hidden'}}>
                <Carousel infiniteLoop showThumbs={false} showStatus={false} swipeable={true} emulateTouch={true}>
                    {images.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt=""/>
                                {legends && legends[index] ? (
                                    <p className={styles.legend}>{legends[index]}</p>
                                ) : null}
                            </div>
                        ))}
                </Carousel>
            </div>
        )
    };
}
export default Gallery;