import styles from '../styles/Page.module.css';
import Button from './Button';

function BlueF1agPriv() {
    return (
        <>
            <div className={styles.div} style={{height:'auto', marginTop:'25px', marginBottom:'100px'}}>
                <a href="/">
                    <Button text="← Home" type="normal"/>
                </a>
                <div style={{width:'90%'}}>
                    <p className={styles.title}>
                        Blue F1ag Privacy Policy
                    </p>
                    <p className={styles.desc}>
                        The use of the Blue F1ag bot on Discord requires the collection of certain user data, including but not limited to Discord usernames and IDs. 
                        By using the bot, you agree to the terms of this privacy policy.
                    </p>
                    <p className={styles.title}>
                        Access to Data
                    </p>
                    <p className={styles.desc}>
                        Access to this data is restricted to the bot's developers and is only used for the development, testing, and implementation of new features. 
                        The data is not sold, shared, or provided to any third parties, except where required by law or in accordance with a terms of service agreement.
                    </p>
                    <p className={styles.title}>
                        Storage of Data
                    </p>
                    <p className={styles.desc}>
                        The data is secured to prevent external access, 
                        however no guarantee is provided and the Bot owners assume no liability for the unintentional or malicious breach of Data. 
                        In the event of an unauthorised Data access, users will be notified through the Discord client application.
                    </p>
                    <p className={styles.title}>
                        User Rights
                    </p>
                    <p className={styles.desc}>
                        You have the right to request a view of the data associated with your Discord account and to request the removal of relevant data.
                    </p>
                    <p className={styles.title}>
                        Underage Users
                    </p>
                    <p className={styles.desc}>
                        The bot is not intended for use by minors under the age of 13 or the age of legal consent in their country, 
                        and no information will be knowingly stored from an underage user.
                    </p>
                    <p className={styles.title}>
                        Questions
                    </p>
                    <p className={styles.desc}>
                        If you have any questions or concerns about the data being stored from your account, please contact .ojee on Discord.
                    </p>
                </div>
            </div>
            <a href="https://buymeacoffee.com/ojee" target="_blank" rel="noopener noreferrer">
              <Button type="support" img="res/icons/bmac.png" text="Support Blue F1ag" />
            </a>
        </>
    )
}

export default BlueF1agPriv;