import styles from '../styles/Page.module.css';
import Button from './Button';

function BlueF1agTos() {
    return (
        <>
            <div className={styles.div} style={{height:'auto', marginTop:'25px', marginBottom:'100px'}}>
                <a href="/">
                    <Button text="← Home" type="normal"/>
                </a>
                <div style={{width:'90%'}}>
                    <p className={styles.title}>
                        Blue F1ag Terms of Service
                    </p>
                    <p className={styles.desc}>
                        By using the Blue F1ag bot on your Discord server, you acknowledge that you have read and agreed to the following terms of service. 
                        It is your responsibility to inform the members of your Discord server about these terms. 
                        If you do not agree with any of the terms listed here, you are not allowed to use or invite Blue F1ag to your server.
                    </p>
                    <p className={styles.title}>
                        Disclaimer
                    </p>
                    <p className={styles.desc}>
                        Please note that the use of Blue F1ag for illegal purposes or in violation of Discord's terms of service is strictly prohibited. 
                        We strive to provide the best user experience while also preventing any inappropriate or illegal use of the bot. 
                        If you come across anyone or any community using Blue F1ag in a way that goes against Discord's terms of service or for illegal activities, 
                        please contact us at "contact@ojee.net".
                    </p>
                </div>
            </div>
            <a href="https://buymeacoffee.com/ojee" target="_blank" rel="noopener noreferrer">
              <Button type="support" img="res/icons/bmac.png" text="Support Blue F1ag" />
            </a>
        </>
    )
}

export default BlueF1agTos;