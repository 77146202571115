import { useState, useEffect, useRef } from 'react';
import styles from '../styles/Page.module.css';
import Button from './Button';
import Select from 'react-select';

const BACKEND = process.env.REACT_APP_BACKEND;

function BlueF1agWeb() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [selectedFunction, setSelectedFunction] = useState('');
    const [showInputs, setShowInputs] = useState(false);
    const [loading, setLoading] = useState(false);
    const [func, setFunc] = useState('');
    const [year, setYear] = useState('');
    const [race, setRace] = useState('');
    const [session, setSession] = useState('');
    const [driver, setDriver] = useState('');
    const [lap, setLap] = useState('');
    const [drivers, setDrivers] = useState([]);
    const [driver1, setDriver1] = useState('');
    const [driver2, setDriver2] = useState('');
    const [lap1, setLap1] = useState('');
    const [lap2, setLap2] = useState('');
    const [dist1, setDist1] = useState('');
    const [dist2, setDist2] = useState('');
    const [result, setResult] = useState('');
    const [datetime, setDatetime] = useState('');
    const [yearOptions, setYearOptions] = useState([]);
    const [raceOptions, setRaceOptions] = useState([]);
    const [sessionOptions, setSessionOptions] = useState([]);
    const [driversOptions, setDriversOptions] = useState([]);
    const [lapsOptions, setLapsOptions] = useState([]);
    const [distOptions, setDistOptions] = useState([]);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://cdn.lordicon.com/ritcuqlt.js';
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);

    const handleFunctionChange = (selectedOption) => {
      setSelectedFunction(selectedOption.value);      
      setFunc(selectedOption.value);
      // set inputs to display if function is selected
      setShowInputs(selectedOption.value !== '');
      setYear('');
      setRace('');
      setSession('');
      setDriver('');
      setLap('');
      setDrivers([]);
      setDriver1('');
      setDriver2('');
      setLap1('');
      setLap2('');
      setDist1('');
      setDist2('');
      setYearOptions([]);
      setRaceOptions([]);
      setSessionOptions([]);
      setDriversOptions([]);
      setLapsOptions([]);
      setDistOptions([]);
      getInputs("years", {func: func});
    };

    const functions = [
        {
          function: 'fastest',
          name: 'Fastest Laps',
          description: 'Gets fastest laps of all drivers for any GP session'
        },
        {
          function: 'results', 
          name: 'Results', 
          description: 'Gets results of any GP session'
        },
        {
          function: 'schedule', 
          name: 'Schedule', 
          description: 'Gets season schedule'
        },
        {
          function: 'event', 
          name: 'Event', 
          description: 'Gets event data'
        },
        {
          function: 'drivers', 
          name: 'Drivers Standings', 
          description: 'Gets driver standings of any year'
        },
        {
          function: 'constructors', 
          name: 'Constructors Standings', 
          description: 'Gets constructors standings of any year'
        },
        {
          function: 'laps', 
          name: 'Laps', 
          description: 'Shows lap times of any session for a list of drivers in a session'
        },
        {
          function: 'time', 
          name: 'Speed/Time', 
          description: 'Shows speed/time over the any lap of a list of drivers in a session'
        },
        {
          function: 'distance', 
          name: 'Speed/Distance', 
          description: 'shows speed/distance over any lap of a list of drivers in a session'
        },
        {
          function: 'delta', 
          name: 'Time Delta', 
          description: 'Compares two drivers speed/distance over any two laps in a session'
        },
        {
          function: 'gear', 
          name: 'Gear', 
          description: 'Shows a gear shift visualization of any lap of a driver in a session'
        },
        {
          function: 'speed', 
          name: 'Speed', 
          description: 'Shows a speed visualization of any lap of a driver in a session'
        },
        {
          function: 'telemetry', 
          name: 'Telemtry', 
          description: 'Gets telemetry data and compares any two laps for two drivers in a session'
        },
        {
          function: 'cornering', 
          name: 'Cornering', 
          description: 'Compares parts of any two laps of any session for two drivers'
        },
        {
          function: 'tires', 
          name: 'Tires', 
          description: 'Compares which tire is fastest in sectors around the track starting at a specific lap'
        },
        {
          function: 'strategy', 
          name: 'Strategy', 
          description: 'Shows tire strategy used by all drivers in any race'
        },
        {
          function: 'sectors', 
          name: 'Sectors', 
          description: 'Shows which of two drivers is fasest in each mini sector in any two laps in a session'
        },
        {
          function: 'racetrace', 
          name: 'Race Trace', 
          description: 'Shows race trace for a list of drivers in a race'
        },
        {
          function: 'positions',
          name: 'Positions',
          description: 'Shows position changes of drivers in a session'
        },
        {
          function: 'points',
          name: 'Points',
          description: 'Shows heatmaps of points scored by drivers in a season'
        },
        {
          function: 'battles',
          name: 'Battles',
          description: 'Shows qualifying battles between drivers in a season'
        }
    ];

    const options = functions.map((func, index) => ({
      value: func.function,
      label: (
        <div>
          <div style={{fontWeight: 'bold'}}>{func.name}</div><div>{func.description}</div>
        </div>
      ),
      name: func.name,
      description: func.description,
    }));

    const functionStyles = {

      control: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '14px',
        textAlign: 'center',
        backgroundColor: '#00aaaa',
        height: '45px',
        border: 'none',
        borderRadius: '10px',
        padding: '5px 10px',
        marginTop: '10px',
        width: '325px',
        borderColor: state.isFocused ? '#00aaaa' : '#00aaaa',
        boxShadow: state.isFocused ? null : null,
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: '#111111',
      }),
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#444444' : '#222222',
        color: 'white',
        height: 'auto',
        minHeight: '45px',
        display: 'flex',
        alignItems: 'center',
        padding: '15px 15px',
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#222222',
      }),
      menuList: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#222222',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#111111',
        lineHeight: '5',
        marginTop: '-20px',
        whiteSpace: 'nowrap', // Add this line to prevent text wrapping
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block', // Change to block
        maxWidth: '100%', // Ensure nowrap works
      }),
      indicatorSeparator: () => ({ display: 'none' }), // Remove the indicator separator
      dropdownIndicator: () => ({ display: 'none' }), // Remove the dropdown indicator
    };

    const inputStyles = {

      control: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '12px',
        backgroundColor: '#00aaaa',
        height: '30px',
        border: 'none',
        borderRadius: '10px',
        padding: '10px 10px',
        marginTop: '10px',
        width: '300px',
        borderColor: state.isFocused ? '#00aaaa' : '#00aaaa',
        boxShadow: state.isFocused ? null : null,
        textAlign: 'left',
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: '#111111',
      }),
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#444444' : '#222222',
        color: 'white',
        height: 'auto',
        minHeight: '45px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#222222',
      }),
      menuList: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#222222',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#111111',
      }),
      indicatorSeparator: () => ({ display: 'none' }), // Remove the indicator separator
      dropdownIndicator: () => ({ display: 'none' }), // Remove the dropdown indicator
      noOptionsMessage: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '14px',
        color: '#aaaaaa',
      }),
    };

    const inputDisabledStyles = {

      control: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '12px',
        backgroundColor: '#333333',
        height: '30px',
        border: 'none',
        borderRadius: '10px',
        padding: '10px 10px',
        marginTop: '10px',
        width: '300px',
        borderColor: state.isFocused ? '#00aaaa' : '#00aaaa',
        boxShadow: state.isFocused ? null : null,
        textAlign: 'left',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: '#aaaaaa',
      }),
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#444444' : '#333333',
        color: 'white',
        height: 'auto',
        minHeight: '45px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#333333',
      }),
      menuList: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#333333',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#aaaaaa',
      }),
      indicatorSeparator: () => ({ display: 'none' }), // Remove the indicator separator
      dropdownIndicator: () => ({ display: 'none' }), // Remove the dropdown indicator
    };

    const driversInputStyles = {

      control: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '12px',
        backgroundColor: '#00aaaa',
        height: '150px',
        border: 'none',
        borderRadius: '10px',
        padding: '10px 10px',
        marginTop: '10px',
        width: '300px',
        borderColor: state.isFocused ? '#00aaaa' : '#00aaaa',
        boxShadow: state.isFocused ? null : null,
        textAlign: 'left',
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: '#111111',
      }),
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#444444' : '#222222',
        color: 'white',
        height: 'auto',
        minHeight: '45px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#222222',
      }),
      menuList: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#222222',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#111111',
      }),
      indicatorSeparator: () => ({ display: 'none' }), // Remove the indicator separator
      dropdownIndicator: () => ({ display: 'none' }), // Remove the dropdown indicator
      multiValue: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? 'transparent' : '#transparent',
      }),
      multiValueLabel: (base, state) => ({
          ...base,
          fontSize: '10px',
          color: state.isFocused ? '#111111' : '#111111',
      }),
      multiValueRemove: (base, state) => ({
          ...base,
          backgroundColor: state.isFocused ? '#00aaaa' : '00aaaa',
          color: state.isFocused ? 'red' : '#111111',
      }),
      clearIndicator: (base, state) => ({
          ...base,
          color: 'red',
      }),
      noOptionsMessage: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '14px',
        color: '#aaaaaa',
      }),
    };

    const driversInputDisabledStyles = {

      control: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '12px',
        backgroundColor: '#333333',
        height: '150px',
        border: 'none',
        borderRadius: '10px',
        padding: '10px 10px',
        marginTop: '10px',
        width: '300px',
        borderColor: state.isFocused ? '#00aaaa' : '#00aaaa',
        boxShadow: state.isFocused ? null : null,
        textAlign: 'left',
        cursor: 'not-allowed',
        pointerEvents: 'none',
      }),
      placeholder: (provided, state) => ({
        ...provided,
        color: '#aaaaaa',
      }),
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Proto',
        fontSize: '14px',
        backgroundColor: state.isFocused ? '#444444' : '#333333',
        color: 'white',
        height: 'auto',
        minHeight: '45px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
      }),
      menu: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#333333',
      }),
      menuList: (provided, state) => ({
        ...provided,
        borderRadius: '10px !important',
        backgroundColor: '#333333',
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#aaaaaa',
      }),
      indicatorSeparator: () => ({ display: 'none' }), // Remove the indicator separator
      dropdownIndicator: () => ({ display: 'none' }), // Remove the dropdown indicator
    };

    const setYearAndCallGetInputs = (year) => {
      setRace('');
      setSession('');
      setDriver('');
      setLap('');
      setDrivers([]);
      setDriver1('');
      setDriver2('');
      setLap1('');
      setLap2('');
      setDist1('');
      setDist2('');
      setYearOptions([]);
      setRaceOptions([]);
      setSessionOptions([]);
      setDriversOptions([]);
      setLapsOptions([]);
      setDistOptions([]);
      if (selectedFunction !== 'battles') {
        getInputs("races", { func: func, year: year });
      } else {
        getInputs("all", { year: year, 'race': null, 'session': null});
      }
    }

    const setRaceAndCallGetInputs = (race) => {
      setSession('');
      setDriver('');
      setLap('');
      setDrivers([]);
      setDriver1('');
      setDriver2('');
      setLap1('');
      setLap2('');
      setDist1('');
      setDist2('');
      setYearOptions([]);
      setRaceOptions([]);
      setSessionOptions([]);
      setDriversOptions([]);
      setLapsOptions([]);
      setDistOptions([]);
      if (selectedFunction !== 'racetrace') {
        getInputs("sessions", { year: year, race: race });
      } else {
        getInputs("all", { year: year, race: race, session: 'Race' })
      };
    }

    const setSessionAndCallGetInputs = (session) => {
      setDriver('');
      setLap('');
      setDrivers([]);
      setDriver1('');
      setDriver2('');
      setLap1('');
      setLap2('');
      setDist1('');
      setDist2('');
      setYearOptions([]);
      setRaceOptions([]);
      setSessionOptions([]);
      setDriversOptions([]);
      setLapsOptions([]);
      setDistOptions([]);
      getInputs("all", { year: year, race: race, session: session });
    }

    const updateInput = (input, value) => {
      switch (input) {
        case 'year':
          setYear(parseInt(value));
          setYearAndCallGetInputs(value);
          break;
        case 'race':
          setRace(value.trim());
          setRaceAndCallGetInputs(value);
          break;
        case 'session':
          setSession(value.trim());
          setSessionAndCallGetInputs(value);
          break;
        case 'driver':
          setDriver(value.trim());
          break;
        case 'lap':
          setLap(parseInt(value));
          break;
        case 'drivers':
          for (var i = 0; i < value.length; i++) {
            value[i] = value[i].trim();
          }
          setDrivers(value);
          break;
        case 'driver1':
          setDriver1(value.trim());
          break;
        case 'driver2':
          setDriver2(value.trim());
          break;
        case 'lap1':
          setLap1(parseInt(value));
          break;
        case 'lap2':
          setLap2(parseInt(value));
          break;
        case 'dist1':
          setDist1(parseInt(value));
          break;
        case 'dist2':
          setDist2(parseInt(value));
          break;
        default:
          break;
      }
    }

    const getInputs = (input, data) => {
      if (selectedFunction === 'racetrace') {
        data["session"] = "race";
      }
      fetch(`${BACKEND}/inputs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({input: input, data: data}),
      })
      .then((res) => res.json())
      .then((res) => {
        if (res["result"].length > 0) {
          switch (input) {
            case 'years':
              setYearOptions(inputsOptionsHelper(res["result"]));
              break;
            case 'races':
              setRaceOptions(inputsOptionsHelper(res["result"]));
              break;
            case 'sessions':
              setSessionOptions(inputsOptionsHelper(res["result"]));
              break;
            case 'all':
              setDriversOptions(inputsOptionsHelper(res["result"][0]));
              setLapsOptions(inputsOptionsHelper(res["result"][1]));
              setDistOptions(inputsOptionsHelper(res["result"][2]));
              break;
            default:
              break;
          }
        } else {
          return [];
        }
      })
      .catch((error) => {
        // if failed to fetch change the error message
        if (error.message.toLowerCase().includes('failed to fetch')) {
          error = 'An error occured while connecting to the server (The server may be offline). Please try again later.';
        }
        alert(error);
        window.location.reload();
      });
    };

    const inputsOptionsHelper = (inputs) => {
      const inputsOptions = inputs.map((input, index) => ({
        value: input.toString(),
        label: input.toString(),
      }));
      return inputsOptions;
    };

    const resultRef = useRef(null);

    const scrollToResult = () => {
      if (resultRef.current) {
        resultRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    };

    const handleSubmit = () => {
      setLoading(true);
      setTimeout(scrollToResult, 100);
      var input_list = {};
      switch (selectedFunction) {
        case 'schedule':
        case 'drivers':
        case 'constructors':
        case 'points':
          input_list = {year: year};
          break;
        case 'event':
        case 'strategy':
        case 'positions':
          input_list = {year: year, race: race};
          break;
        case 'fastest':
        case 'results':
          input_list = {year: year, race: race, session: session};
          break;
        case 'tires':
          input_list = {year: year, race: race, session: session, lap: lap};
          break;
        case 'racetrace':
          input_list = {year: year, race: race, drivers: drivers};
          break;
        case 'laps':
          input_list = {year: year, race: race, session: session, drivers: drivers};
          break;
        case 'gear':
        case 'speed':
          if (driver === '') {
            setDriver(null);
          }
          if (lap === '') {
            setLap(null);
          }
          input_list = {year: year, race: race, session: session, driver: driver, lap: lap};
          break;
        case 'time':
        case 'distance':
          input_list = {year: year, race: race, session: session, drivers: driver, lap: lap};
          break;
        case 'delta':
        case 'telemetry':
        case 'sectors':
          if (driver1 === '') {
            setDriver1(null);
          }
          if (driver2 === '') {
            setDriver2(null);
          }
          if (lap1 === '') {
            setLap1(null);
          }
          if (lap2 === '') {
            setLap2(null);
          }
          input_list = {year: year, race: race, session: session, driver1: driver1, driver2: driver2, lap1: lap1, lap2: lap2};
          break;
        case 'cornering':
          if (driver1 === '') {
            setDriver1(null);
          }
          if (driver2 === '') {
            setDriver2(null);
          }
          if (lap1 === '') {
            setLap1(null);
          }
          if (lap2 === '') {
            setLap2(null);
          }
          if (dist1 === '') {
            setDist1(null);
          }
          if (dist2 === '') {
            setDist2(null);
          }
          input_list = {year: year, race: race, session: session, driver1: driver1, driver2: driver2, lap1: lap1, lap2: lap2, dist1: dist1, dist2: dist2};
          break;
        case 'battles':
          input_list = {year: year, drivers: drivers};
        default:
          break;
      }

      var user_id = '';
      fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        user_id = data.ip;
      })
      .catch((error) => console.log(error));

      fetch(`${BACKEND}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "func_name": selectedFunction,
            "user_id": user_id,
            "input_list": input_list
        }),
      })
      .then(response => response.json())
      .then(data => {
          function arrayBufferToBase64(buffer) {
              let binary = '';
              let bytes = new Uint8Array(buffer);
              let len = bytes.byteLength;
              for (let i = 0; i < len; i++) {
                  binary += String.fromCharCode(bytes[i]);
              }
              return window.btoa(binary);
          }
          if (data["error"]) {
            alert(data["error"]);
            setLoading(false);
          } else {
            setResult(URL.createObjectURL(new Blob([new Uint8Array(data["result"])])));
            setDatetime(data["datetime"]);
            setLoading(false);
            scrollToResult();
          }
      })
      .catch((error) => {
        alert(error);
        window.location.reload();
      });
    };

    const handleDownload = async () => {
      if (result !== '') {
        try {
          const link = document.createElement('a');
          link.href = result;
          link.download = datetime + '.png';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          alert(error);
        }
      }
    };

    return (
        <>
            <div className={styles.div} style={{height:'auto', marginTop:'25px', marginBottom:'100px'}}>
                <div className={styles.div} style={{height: 'auto', width: isMobile ? '325px' : '740px', marginTop:'25px', marginBottom:'50px', padding:'10px 20px', borderRadius:'10px', backgroundColor:'#222222', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? 'center' : 'space-between', gap: isMobile ? 'auto' : '50px'}}>
                    <div>
                        <a href="/">
                          <img src="res/icons/bf1.png" alt="Blue F1ag" style={{width:'325px', height:'auto', borderRadius:'10px', marginTop: isMobile ? '10px' : '-27.5px', marginBottom:'25px'}} />
                        </a>
                        <p className={styles.title} style={{width: '325px', marginBottom: '50px'}}>Blue F1ag provides F1 data through graphs for fans to analyze.</p>
                    </div>
                    <div className={styles.div2} style={{gap: isMobile ? 'auto' : '15px', marginBottom: isMobile ? '10px' : '0px', marginTop: '2.5px'}}>
                        <Select id="funcSelect" placeholder="Select a fucntion ▼" noOptionsMessage={() => "Loading..."} options={options} styles={functionStyles} maxMenuHeight={isMobile? 455 : 465} isSearchable={false} onChange={handleFunctionChange}/>
                      <div className={styles.div} style={{justifyContent: 'flex-start', alignItems: 'center', backgroundColor: '#111111', width: '325px', height: '450px', borderRadius: '10px', marginTop: isMobile ? '10px' : '5px'}}>
                        <div id="inputs" style={{display: showInputs ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', alignSelf: 'center', marginTop: '4px'}}>
                          {(selectedFunction === 'schedule' ||
                            selectedFunction === 'drivers' ||
                            selectedFunction === 'constructors' ||
                            selectedFunction === 'points') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                            </>
                          )}
                          {(selectedFunction === 'event' || 
                            selectedFunction === 'strategy' ||
                            selectedFunction === 'positions') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                            </>
                          )}
                          {(selectedFunction === 'fastest' || 
                            selectedFunction === 'results')  && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                              {race === ''
                                ? <Select placeholder="Please select a race first" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a session ▼" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('session', selectedOption.value)} />
                              }
                            </>
                          )}
                          {(selectedFunction === 'tires') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                              {race === ''
                                ? <Select placeholder="Please select a race first" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a session ▼" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('session', selectedOption.value)} />
                              }
                              {session === ''
                                ? <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a lap ▼" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('lap', selectedOption.value)} />
                              }
                            </>
                          )}
                          {(selectedFunction === 'racetrace') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                              {race === ''
                                ? <Select placeholder="Please select a race first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={driversInputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select drivers ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={driversInputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} isMulti onChange={(selectedOptions) => updateInput('drivers', selectedOptions.map(option => option.value))} />
                              }
                            </>
                          )}
                          {(selectedFunction === 'laps') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                              {race === ''
                                ? <Select placeholder="Please select a race first" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select session ▼" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('session', selectedOption.value)} />
                              }
                              {session === ''
                                ? <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={driversInputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select drivers ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={driversInputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} isMulti onChange={(selectedOptions) => updateInput('drivers', selectedOptions.map(option => option.value))} />
                               }
                            </>
                          )}
                          {(selectedFunction === 'gear' || 
                            selectedFunction === 'speed') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                              {race === ''
                                ? <Select placeholder="Please select a race first" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select session ▼" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('session', selectedOption.value)} />
                              }
                              {session === ''
                                ? <>
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                  </>
                                : <>
                                  <Select placeholder="Please select a driver ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('driver', selectedOption.value)} />
                                  <Select placeholder="Please select a lap ▼" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('lap', selectedOption.value)} />
                                  </>
                              }
                            </>
                          )}
                          {(selectedFunction === 'time' || 
                            selectedFunction === 'distance') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                              {race === ''
                                ? <Select placeholder="Please select a race first" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select session ▼" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('session', selectedOption.value)} />
                              }
                              {session === ''
                                ? <>
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={driversInputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                  </>
                                : <>
                                  <Select placeholder="Please select a lap ▼" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('lap', selectedOption.value)} />
                                  <Select placeholder="Please select drivers ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={driversInputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} isMulti onChange={(selectedOptions) => updateInput('drivers', selectedOptions.map(option => option.value))} />
                                  </>
                              }
                            </>
                          )}
                          {(selectedFunction === 'delta' || 
                            selectedFunction === 'telemetry' || 
                            selectedFunction === 'sectors') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                              {race === ''
                                ? <Select placeholder="Please select a race first" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select session ▼" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('session', selectedOption.value)} />
                              }
                              {session === ''
                                ? <>
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 355 : 365} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 305 : 315} isSearchable={false} />
                                  </>
                                : <>  
                                  <Select placeholder="Please select first driver ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('driver1', selectedOption.value)} />
                                  <Select placeholder="Please select second driver ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('driver2', selectedOption.value)} />
                                  <Select placeholder="Please select first lap ▼" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputStyles} maxMenuHeight={isMobile? 355 : 365} isSearchable={false} onChange={(selectedOption) => updateInput('lap1', selectedOption.value)} />
                                  <Select placeholder="Please select second lap ▼" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputStyles} maxMenuHeight={isMobile? 305 : 315} isSearchable={false} onChange={(selectedOption) => updateInput('lap2', selectedOption.value)} />
                                  </>
                              }
                            </>
                          )}
                          {(selectedFunction === 'cornering') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select a race ▼" noOptionsMessage={() => "Loading..."} options={raceOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('race', selectedOption.value)} />
                              }
                              {race === ''
                                ? <Select placeholder="Please select a race first" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select session ▼" noOptionsMessage={() => "Loading..."} options={sessionOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('session', selectedOption.value)} />
                              }
                              {session === ''
                                ? <>
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 355 : 365} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 305 : 315} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={distOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 255 : 265} isSearchable={false} />
                                  <Select placeholder="Please select a session first" noOptionsMessage={() => "Loading..."} options={distOptions} styles={inputDisabledStyles} maxMenuHeight={isMobile? 205 : 215} isSearchable={false} />
                                  </>
                                : <>
                                  <Select placeholder="Please select first driver ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('driver1', selectedOption.value)} />
                                  <Select placeholder="Please select second driver ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('driver2', selectedOption.value)} />
                                  <Select placeholder="Please select first lap ▼" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputStyles} maxMenuHeight={isMobile? 355 : 365} isSearchable={false} onChange={(selectedOption) => updateInput('lap1', selectedOption.value)} />
                                  <Select placeholder="Please select second lap ▼" noOptionsMessage={() => "Loading..."} options={lapsOptions} styles={inputStyles} maxMenuHeight={isMobile? 305 : 315} isSearchable={false} onChange={(selectedOption) => updateInput('lap2', selectedOption.value)} />
                                  <Select placeholder="Please select first distance ▼" noOptionsMessage={() => "Loading..."} options={distOptions} styles={inputStyles} maxMenuHeight={isMobile? 255 : 265} isSearchable={false} onChange={(selectedOption) => updateInput('dist1', selectedOption.value)} />
                                  <Select placeholder="Please select second distance ▼" noOptionsMessage={() => "Loading..."} options={distOptions} styles={inputStyles} maxMenuHeight={isMobile? 205 : 215} isSearchable={false} onChange={(selectedOption) => updateInput('dist2', selectedOption.value)} />
                                  </>
                              }
                            </>
                          )}
                          {(selectedFunction === 'battles') && (
                            <>
                              <Select placeholder="Please select a year ▼" noOptionsMessage={() => "Loading..."} options={yearOptions} styles={inputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} onChange={(selectedOption) => updateInput('year', selectedOption.value)} />
                              {year === '' 
                                ? <Select placeholder="Please select a year first" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={driversInputDisabledStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} />
                                : <Select placeholder="Please select drivers ▼" noOptionsMessage={() => "Loading..."} options={driversOptions} styles={driversInputStyles} maxMenuHeight={isMobile? 405 : 415} isSearchable={false} isMulti onChange={(selectedOptions) => updateInput('drivers', selectedOptions.map(option => option.value))} />
                              }
                            </> 
                          )}
                        </div>
                      </div>
                      { (selectedFunction).length === 0 ||
                        ((selectedFunction === 'schedule' || selectedFunction === 'drivers' || selectedFunction === 'constructors' || selectedFunction === 'points') && (year === '')) ||
                        ((selectedFunction === 'event' || selectedFunction === 'strategy' || selectedFunction === 'positions') && (year === '' || race === '')) ||
                        ((selectedFunction === 'fastest' || selectedFunction === 'results' || selectedFunction === 'gear' || selectedFunction === 'speed' || selectedFunction === 'delta' || selectedFunction === 'telemetry' || selectedFunction === 'sectors' || selectedFunction === 'cornering') && (year === '' || race === '' || session === '')) ||
                        ((selectedFunction === 'tires') && (year === '' || race === '' || session === '' || lap === '')) ||
                        ((selectedFunction === 'racetrace') && (year === '' || race === '' || drivers.length === 0)) ||
                        ((selectedFunction === 'laps' || selectedFunction === 'time' || selectedFunction === 'distance') && (year === '' || race === '' || session === '' || drivers.length === 0)) ||
                        ((selectedFunction === 'battles') && (year === '' || drivers.length === 0))
                        ? <Button type="submit_disabled" img="" text="Submit ➜" />
                        : <>
                          <div onClick={handleSubmit}>
                          <Button type="bf1" img="" text="Submit ➜" />
                          </div>
                          </>
                      }
                    </div>
                </div>
                {(result !== '' || loading === true) && (
                <>
                <div id="result" ref={resultRef} className={styles.div} style={{height: 'auto', width: isMobile ? '325px' : '740px', marginTop:'25px', marginBottom:'50px', padding:'10px 20px', borderRadius:'10px', backgroundColor:'#222222', justifyContent: 'center', gap: '25px'}}>
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: isMobile ? '25px' : '50px'}}>
                    <Button type="res" img="" text="Result" />
                    <div onClick={handleDownload}>
                    <Button type={result === '' ? "download_disabled" : "download"} img="" text="Download ↓" />
                    </div>
                  </div>
                  {loading === true 
                    ? 
                    <>
                    <div>
                      <br /><br />
                      <script src="https://cdn.lordicon.com/ritcuqlt.js"></script>
                      <lord-icon
                        class="Lordicon"
                        src="https://cdn.lordicon.com/dpinvufc.json"
                        trigger="loop"
                        colors="primary:#00aaaa,secondary:#222222"
                        style={{ width: '250px', height: '250px', textAlign: 'center', marginTop: '-50px' }}
                      >
                      </lord-icon>
                    </div>
                    </>
                    :
                    <>
                    <img id="result" src={result} alt="" style={{width: isMobile ? '305px' : '720px', height: 'auto', borderRadius: '10px', marginBottom: '25px'}} />
                    </>
                  }
                </div>
                </>
                )}
            </div>
            <a href="https://buymeacoffee.com/ojee" target="_blank" rel="noopener noreferrer">
              <Button type="support" img="res/icons/bmac.png" text="Support Blue F1ag" />
            </a>
        </>
    )
}

export default BlueF1agWeb;