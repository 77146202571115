import styles from '../styles/Navbar.module.css';

const OJEE = process.env.REACT_APP_OJEE;
const BLUEF1AG = process.env.REACT_APP_BLUEF1AG;

function Navbar() {
  return (
    <>
        <nav className={styles.navbar}>
            <div className={styles.logo}>
                <a href={"https://" + OJEE} rel="noreferrer">
                    <img className={styles.navbar_img} src="/res/favicon.png" alt="logo" />
                </a>
            </div>
        </nav>
    </>
  )
}

export default Navbar;