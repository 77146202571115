import { useState, useEffect } from 'react';
import styles from '../styles/Page.module.css';
import Button from './Button';
import Gallery from './Gallery';

function BlueF1ag() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const bf1_images = [
        'res/f1/f1.png',
        'res/f1/f2.png',
        'res/f1/f3.png',
        'res/f1/f4.png',
        'res/f1/f5.png',
        'res/f1/f6.png',
        'res/f1/f7.png',
        'res/f1/f8.png',
        'res/f1/f9.png',
        'res/f1/f10.png',
        'res/f1/f11.png',
        'res/f1/f12.png',
        'res/f1/f13.png',
        'res/f1/f14.png',
        'res/f1/f15.png',
        'res/f1/f16.png',
        'res/f1/f17.png',
        'res/f1/f18.png',
        'res/f1/f19.png',
        'res/f1/f20.png',
        'res/f1/f21.png'
    ];

    const bf1_legends = [
        'Fastest Laps',
        'Results',
        'Schedule',
        'Event',
        'Drivers Standings',
        'Constructors Standings',
        'Laps',
        'Speed/Time',
        'Speed/Distance',
        'Time Delta',
        'Gear',
        'Speed',
        'Telemetry',
        'Cornering',
        'Tires',
        'Strategy',
        'Sectors',
        'Race Trace',
        'Position Changes',
        'Points Heatmap',
        'Qualifying Battles'
    ];

    return (
        <>
            <div className={styles.div} style={{height:'auto', marginTop:'25px', marginBottom:'100px'}}>
                {/* <h1 className={styles.title} style={{fontSize: '22px', textAlign:'center', marginRight:'25px', marginLeft:'25px', color:'#aa0000', backgroundColor: '#151515', borderRadius: '10px', padding: '10px 20px', width: isMobile ? '325px' : '740px'}}>
                    The Blue F1ag Project is down for maintenance and will be available very soon. For any questions feel free to dm me on discord (.ojee).
                </h1> */}
                <div className={styles.div} style={{height: 'auto', width: isMobile ? '325px' : '740px', marginTop:'25px', marginBottom:'50px', padding:'10px 20px', borderRadius:'10px', backgroundColor:'#222222', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? 'center' : 'space-between', gap: isMobile ? 'auto' : '50px'}}>
                    <div>
                        <a href="/">
                            <img src="res/icons/bf1.png" alt="Blue F1ag" style={{width:'325px', height:'auto', borderRadius:'10px', marginTop:'10px', marginBottom:'25px'}} />
                        </a>
                        <p className={styles.title} style={{width: '325px', marginBottom: '50px'}}>Blue F1ag provides F1 data through graphs for fans to analyze.</p>
                    </div>
                    <div className={styles.div} style={{gap: isMobile ? 'auto' : '35px', marginTop: isMobile ? '0px' : '0px'}}>
                        <a href="/web">
                            <Button text="Try Blue F1ag Web →" img="res/icons/bf1-mini.png" type="bf1"/>
                        </a>
                        <a target="_blank" rel='noreferrer' href="https://github.com/0J33/BlueF1ag">
                            <Button text="Blue F1ag on Github" img="res/icons/git2.png" type="bf1"/>
                        </a>
                        <a target="_blank" rel='noreferrer' href="https://discord.com/oauth2/authorize?client_id=892359806898303036&permissions=534723947584&scope=bot">
                            <Button text="Add Blue F1ag to your server" img="res/icons/disc.png" type="bf1"/>
                        </a>
                        <a target="_blank" rel='noreferrer' href="https://discord.com/invite/uXY5Va4Jbb">
                            <Button text="Blue F1ag Support Server" img="res/icons/disc.png" type="bf1"/>
                        </a>
                        <a href="/tos">
                            <Button text="Blue F1ag ToS" img="res/icons/scr.png" type="bf1"/>
                        </a>
                        <a href="/priv">
                            <Button text="Blue Flag Privacy Policy" img="res/icons/scr.png" type="bf1"/>
                        </a>
                    </div>
                </div>
                <div style={{width: isMobile ? '365px' : '780px'}}>
                    <Gallery images={bf1_images} legends ={bf1_legends} />
                </div>
            </div>
            <a href="https://buymeacoffee.com/ojee" target="_blank" rel="noopener noreferrer">
              <Button type="support" img="res/icons/bmac.png" text="Support Blue F1ag" />
            </a>
        </>
    )
}

export default BlueF1ag;