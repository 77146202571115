import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BlueF1ag from './components/BlueF1ag';
import BlueF1agWeb from './components/BlueF1agWeb';
import BlueF1agTos from './components/BlueF1agTos';
import BlueF1agPriv from './components/BlueF1agPriv';
import Navbar from './components/Navbar';
import Contact from './components/Contact';
import styles from './styles/App.module.css';

const OJEE = process.env.REACT_APP_OJEE;
const BLUEF1AG = process.env.REACT_APP_BLUEF1AG;

function App() {
    return (

        <>
            <Navbar />
            <Router>
                <Routes>
                    <Route path="/" element={<BlueF1ag />} />
                    {/* <Route path="/bluef1ag" element={<BlueF1ag />} /> */}
                    <Route path="/web" element={<BlueF1agWeb />} />
                    <Route path="/tos" element={<BlueF1agTos />} />
                    <Route path="/priv" element={<BlueF1agPriv />} />
                    {/* <Route path="/cv" element={<CV />} /> */}
                    <Route path="*" element={<BlueF1ag />} />
                </Routes>
            </Router>
            <Contact />
        </>
        
    );
}

export default App;