import styles from '../styles/Button.module.css';

function Button(props){
    if (props.type === "normal"){
        if (props.img != null){
            return <button className={styles.button}><img src={props.img} className={styles.button_img}/>{props.text}</button>
        } else {
            return <button className={styles.button}>{props.text}</button>
        }
    } else if (props.type === "bf1") {
        return <button className={styles.button2}><img src={props.img} className={styles.button_img2}/>{props.text}</button>
    } else if (props.type === "bf1_disabled") {
        return <button className={styles.button2_disabled}><img src={props.img} className={styles.button_img2_disabled}/>{props.text}</button>
    } else if (props.type === "support") {
        return <button className={styles.button_support}><img src={props.img} className={styles.button_img_support}/>{props.text}</button>
    } else if (props.type === "res") {
        return <button className={styles.button_res}>{props.text}</button>
    } else if (props.type === "download") {
        return <button className={styles.button_download}>{props.text}</button>
    } else if (props.type === "download_disabled") {
        return <button className={styles.button_download_disabled}>{props.text}</button>
    } else if (props.type === "submit_disabled") {
        return <button className={styles.button_submit_disabled}>{props.text}</button>
    }
}

export default Button;